<template>
  <Header
      is-home="true"
      title="Custom Mattresses"
      lead="Quality mattresses of all shapes and sizes"
      bg-image="custom-banner.jpg"/>
  <main>
    <section class="container-xl intro-section">
      <div class="row align-items-center">
        <div class="col-12 col-md-6 h-100">
          <h2 class="display-6">Looking for a uniquely sized mattress?</h2>
          <p class="lead">Thomasville Bedding is one of the few places in the United States still offering USA made,
            handcrafted, custom mattresses.
            Whether you have an antique or odd-shaped bed frame, a boat bed or other non-standard bed frame, we can
            craft a custom boxspring and mattress for unbeatable factory-direct prices.
          </p>
        </div>
        <div class="col-12 col-md-5 offset-md-1">
          <div class="img-container">
            <img src="../assets/img/custom-mattress-2.jpg" class="img-fluid rounded-circle  shadow-lg" alt="">
          </div>
        </div>
      </div>
    </section>
    <section class="container-xl get-started">
      <div class="row">
        <div class="col-12">
          <h3 class="display-6 mb-5">How to get started with your custom mattress</h3>
        </div>
        <div class="col-12 col-md-4">
          <div class="rounded-lg text-white p-5 mb-5 steps position-relative">
            <div class="position-absolute icon"><i class="fa-light fa-ruler-triangle"></i></div>
            <h4 class="">Step 1</h4>
            <p class="mb-0">Take measurements of your uniquely-sized bed. (length, width, and height)</p>
          </div>
        </div>
        <div class="col-12 col-md-4">
          <div class="rounded-lg text-white p-5 mb-5 steps position-relative">
            <div class="position-absolute icon"><i class="fa-light fa-phone"></i></div>
            <h4 class="">Step 2</h4>
            <p class="mb-0">Call us to discuss your mattress preferences with our professional staff</p>
          </div>
        </div>
        <div class="col-12 col-md-4">
          <div class="rounded-lg text-white p-5 mb-5 steps position-relative">
            <div class="position-absolute icon"><i class="fa-light fa-truck-container"></i></div>
            <h4 class="">Step 3</h4>
            <p class="mb-0">Receive a beautiful, custom boxspring and mattress delivered to your door</p>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import Header from '@/components/Header'

export default {
  name: 'CustomMattresses',
  components: { Header },
  mounted () {
    document.title = 'Custom Mattresses by Thomasville Bedding'
  }
}
</script>

<style scoped lang="scss">
.intro-section {
  margin: 120px auto;
}

.steps {
  background-color: $red;
  font-size: 1.5em;
  font-weight: 200;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  h4 {
    color: white;
    font-size: 1.75em;

  }

  .icon {
    top: 7px;
    right: 15px;
    font-size: 1.3em;
  }
}
.get-started {
  margin-bottom: 120px;
}
</style>
